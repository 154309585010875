import React, { useState, useEffect } from 'react';

interface ShowTextByLetterProps {
  text: string;
  delay?: number;
  infinite?: boolean;
  callback?: () => void;
  deleteTextByLetterBeforeCallback?: boolean;
}

const ShowTextByLetter: React.FC<ShowTextByLetterProps> = (
  {
    text,
    delay = 3,
    infinite = false,
    callback,
    deleteTextByLetterBeforeCallback = false,
  },
) => {
  const [currentText, setCurrentText] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [backWardAnimationActive, setBackwardAnimationActive] = useState<boolean>(false);

  useEffect(() => {
    setCurrentText('');
    setCurrentIndex(0);
  }, [text]);

  useEffect(() => {
    let addTextTimeout: any;

    if (!backWardAnimationActive) {
      if (currentIndex < text.length) {
        addTextTimeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);
      } else if (callback && !deleteTextByLetterBeforeCallback) {
        callback();
      } else if (deleteTextByLetterBeforeCallback) {
        if (addTextTimeout) {
          clearTimeout(addTextTimeout);
        }

        setTimeout(() => {
          setBackwardAnimationActive(true);
        }, 400);
      } else if (infinite) {
        setCurrentIndex(0);
        setCurrentText('');
      }
    }

    return () => {
      clearTimeout(addTextTimeout);
    };
  }, [text, currentText]);

  useEffect(() => {
    let deleteTextTimeout: any;

    if (backWardAnimationActive) {
      if (deleteTextByLetterBeforeCallback && currentText.length > 0 && callback) {
        deleteTextTimeout = setTimeout(() => {
          setCurrentText((prevText) => prevText.substring(0, prevText.length - 1));
          setCurrentIndex((prevIndex) => prevIndex - 1);
        }, delay);
      } else if (callback) {
        if (deleteTextTimeout) {
          clearTimeout(deleteTextTimeout);
        }

        setBackwardAnimationActive(false);

        callback();
      }
    }

    return () => {
      clearTimeout(deleteTextTimeout);
    };
  }, [backWardAnimationActive, currentText]);

  return <span className="textByLetter">{currentText}</span>;
};

export default ShowTextByLetter;
